import { memo, useEffect } from 'react';
// @mui
import AppBar from '@mui/material/AppBar';
// theme
// components
//
import { HEADER } from '../config-layout';
import { useSettingsContext } from '../../components/settings';

// ----------------------------------------------------------------------

function NavHorizontal() {
  const settings = useSettingsContext();

  useEffect(() => {
    // if the path don't contain /edit , set the layout to vertical
    if (!window.location.pathname.includes('/edit')) {
      settings.onUpdate('themeLayout', 'mini');
    }
  }, [settings]);

  return (
    <AppBar
      component="nav"
      sx={{
        top: HEADER.H_DESKTOP_OFFSET,
      }}
    />
  );
}

export default memo(NavHorizontal);
